import React from "react";
import { navigate } from "gatsby";

import ShopLayout from "../components/new-layout";
import Breadcrumbs from "../components/breadcrumbs";
import Newsletter from "../components/shop-newsletter";
import { Mail, Facebook, Instagram, WhatsApp } from "../components/shop-icons";
import Swal from "sweetalert2";

const Contact = () => {
  let links = [
    ["/platform", "Home"],
    ["/contact", "Contact Us"],
  ];

  return (
    <ShopLayout>
      <Breadcrumbs links={links} />
      <div className="container md:pl-12 my-10">
        <div className="grid md:grid-cols-2 gap-16">
          <div className="bg-pink-500 p-6 text-white rounded-2xl">
            <h1 className="mt-6 mb-4 text-3xl font-display">Get in Touch</h1>
            <input
              className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 
                placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
              type="text"
              name="name"
              placeholder="Your name"
              required
            />
            <input
              className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 
                placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
              type="email"
              name="email"
              placeholder="Your email"
              required
            />
            <textarea
              className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4
                placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4 resize-none"
              name="message"
              placeholder="Write your message"
              rows={5}
              required
            />

            <div className="text-right">
              <button
                onClick={() => {
                  Swal.fire(
                    "Thank you!",
                    "Message sent, do some shopping now!",
                    "success"
                  );
                  navigate("/store");
                }}
                type="submit"
                className="font-display bg-blue-700 border-2 border-blue-700 rounded-lg px-8 py-3 
                  placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4 resize-none"
              >
                Submit
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center space-y-4 w-full md:max-w-sm text-blue-700 mx-auto">
            <div className="flex items-center space-x-4">
              <a
                href="https://wa.me/+351913343808"
                className="p-2 text-white bg-blue-900 rounded-full"
                target="_blank"
                rel="noreferrer"
              >
                <WhatsApp className="w-5 h-5 fill-current" />
              </a>
              <a
                href="https://wa.me/+351913343808"
                target="_blank"
                rel="noreferrer"
              >
                +351 932 197 656
              </a>
            </div>
            <div className="flex items-center space-x-4">
              <a
                href="https://www.facebook.com/meiapata"
                className="p-2 text-white bg-blue-900 rounded-full"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook className="w-5 h-5 fill-current" />
              </a>
              <a
                href="https://www.facebook.com/meiapata"
                target="_blank"
                rel="noreferrer"
                style={{ textTransform: "none" }}
              >
                @meiapata
              </a>
            </div>
            <div className="flex items-center space-x-4">
              <a
                href="https://www.instagram.com/meiapata/"
                className="p-2 text-white bg-blue-900 rounded-full"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram className="w-5 h-5 fill-current" />
              </a>
              <a
                href="https://www.instagram.com/meiapata/"
                target="_blank"
                rel="noreferrer"
                style={{ textTransform: "none" }}
              >
                @meiapata
              </a>
            </div>
            <div className="flex items-center space-x-4">
              <a
                href="mailto:hello@meiapata.pt"
                className="p-2 text-white bg-blue-900 rounded-full"
                rel="noreferrer"
              >
                <Mail className="w-5 h-5 stroke-current" />
              </a>
              <a
                href="mailto:hello@meiapata.pt"
                style={{ textTransform: "none" }}
              >
                hello@meiapata.pt
              </a>
            </div>
            <div>
              <p className="max-w-xs mt-6 mb-2">
                Rua José Cavadas, 8 <br />
                4780-486 Santo Tirso Portugal
              </p>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
    </ShopLayout>
  );
};

export default Contact;
